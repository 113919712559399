.edit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.account-wrapper {
  font-family: "Open Sans";
  @extend .pageFrame !optional;
  .account-body {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .account-tiles-wrapper {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      // z-index: 1005;
      .account-tile {
        display: flex;
        align-items: center;
        width: 100%;
        height: 4rem;
        box-sizing: border-box;
        background: #181c27;
        border: 2px solid #686868;
        backdrop-filter: blur(11px);
        border-radius: 6px;
        margin: 0.5rem 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155.2%;
        color: #c5c5c5;
        .icon {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tile-title {
          margin: 0 0.5rem;
        }
      }
    }
    
  }
}

.bottom-button {
  width: 100%;
  height: 4rem;
  background: #181c27;
  border: 2px solid #42404b;
  backdrop-filter: blur(11px);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Days One";
  font-weight: 600;
  font-size: 20px;
  color: #c5c5c5;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.bottom-button:active {
  transform: translateY(-3rem);
}


.to-account-input-wrapper {
  width: 100%;

  .icon {
    transform: translateY(-2.25rem) translateX(0.75rem);
  }
  .deposit-label {
    color: white;
    display: flex;
    padding: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    padding: 3px;
    /* or 20px */

    color: #ffffff;
  }
  input[type="text"],
  input[type="email"] {
    all: unset;
    padding: 1rem 1rem 1rem 2.5rem;
    box-sizing: border-box;

    background: #181c27;
    border: 2px solid #42404b;
    backdrop-filter: blur(11px);
    text-align: left;
    border-radius: 6px;
    width: 100%;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155.2%;
    color: #c5c5c5;
  }
  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
  }
}
.to-account-message-input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    top: 4.2rem;
    left: 0.75rem;
  }
  .deposit-label {
    margin: 1rem 0 0.5rem 0;
    color: white;
    display: flex;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 155.2%;
    /* or 20px */

    color: #ffffff;
  }
  textarea[type="text"],
  input[type="email"] {
    all: unset;
    height: 80%;
    resize: vertical; /* user can resize vertically but not horizontally */
    overflow-x: hidden; /* prevent horizontal scroll */
    white-space: pre-wrap; /* wrap text inside textarea */
    word-wrap: break-word; /* break words to prevent overflow */
    padding: 1rem 1rem 1rem 2.5rem;
    box-sizing: border-box;

    background: #181c27;
    border: 2px solid #42404b;
    backdrop-filter: blur(11px);
    text-align: left;
    border-radius: 6px;
    width: 100%;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155.2%;
    color: #c5c5c5;
  }
  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
  }
}

.update-save,
  .update-cancel {
    width: 45%;
    height: 3.5rem;
    box-sizing: border-box;
    background: #181c27;
    border: 2px solid #42404b;
    backdrop-filter: blur(11px);
    border-radius: 6px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 22px */

    color: #c5c5c5;
    &.active {
      background: #2d65c1;
    }
  }
  
.account-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  .update-save,
  .update-cancel {
    width: 45%;
    height: 3.5rem;
    box-sizing: border-box;
    background: #181c27;
    border: 2px solid #42404b;
    backdrop-filter: blur(11px);
    border-radius: 6px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 22px */

    color: #c5c5c5;
    &.active {
      background: #2d65c1;
    }
  }
}
#radsoftware-bg {
  position: absolute;
  left: 0;
  // z-index: 0;
}

.support-wrapper, .support-body {
  max-width: 450px;
  max-height: 1000px;
}

