@import "./controller/barDiceController.scss";
@import "./login/barDiceLogin.scss";
@import "./terminal/barDiceTerminal.scss";

#barDice {
  color: white;
  font-family: 'Open Sans';
  
  .gradientBg {
    border-radius: 2vh;
    background: #42404B;
    padding: 1px;
  
    .blackBg {
      background: black;
      background: linear-gradient(
        180deg,
        #181c27 0%,
        rgba(24, 28, 39, 0.95) 100%
      );
      backdrop-filter: blur(11px);
      border-radius: 2vh;
      position: relative;
    }
  }

  #die { position: absolute; }
}  