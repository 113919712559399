#error {
  width: 100%;
  height: 100%;
  background-color: white;

  .errorFrame {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: clamp(250px, 80%, 450px);
    margin: 0 auto;
  }

  .errorLogo {margin: 0 auto;}

  .errorHeader {
    color: black;
    font-family: "Days One";
    font-size: 18px;
    text-align: center;
  }
  .errorBody {
    color: #535353;
    font-family: "Open Sans";
    height: 50%;
    ol {padding-left: 16px;}
    li {margin-bottom: 16px;}
  }

  .errorButton {
    color: white;
    background-color: #2D65C1;
    font-family: "Days One";
    text-transform: uppercase;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;

    &.disabled {background-color: grey;}
  }
}