@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@300;400;500;600;700&family=Days+One&family=Open+Sans:wght@400;600;700&display=swap");

@font-face {
  font-family: "Metropolis";
  src: url("./assets/fonts/Metropolis-Regular.otf");
}

@font-face {
  font-family: "Open Sans Condensed Bold";
  src: url("./assets/fonts/OpenSans-CondBold.ttf")
}

@mixin invisibleInput {
  background: transparent;
  border: none;
  color: white;
  &::placeholder { color: #888888; }
  &:focus { outline: none; }
}

.invisibleInput {
  @include invisibleInput;
}

.hide {display: none !important;}

.row {
  display: flex;
  justify-content: space-between;
}
.col {
  display: flex; 
  flex-direction: column;
}

.pageFrame {
  height: 100%;
  width: 90%;
  max-width: 450px;
  max-height: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  nav {margin-top: 1rem;}
  >:last-child {margin-bottom: 1rem;}
}

#modal {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

.blurred {
  filter: blur(2px);
}

.jackpotFont {
  text-shadow: -1px 0 #A9140B, 0 1px #A9140B, 1px 0 #A9140B, 0 -1px #A9140B;
  font-family: 'Days One';
  text-align: center;
}

.jackpotShield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .jackpotAmount {
    @extend .jackpotFont;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#jackpotModal {
  // z-index: -100 !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;

  .jackpotCenterContainer {
    width: 100%;
    max-width: 450px !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .jackpotRules {
    // height: 60vh;
    max-height: 500px;
    color: black;
    background-color: #F0F0F6;
    padding: clamp(10px, 5vw, 20px);
    border: 2px solid #42404B;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >div {margin-bottom: clamp(12px, 2vh, 20px);}
    .bold {font-weight: 700;}
    ul {
      margin: 0;
      padding-left: 1rem;
    }
    li {
      width: 100%;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  .jackpotModalCloseButton {
    font-family: "Days One";
    font-size: clamp(15px, 4vw, 18px);
    padding: 2vh;
    text-transform: uppercase;
    text-align: center;
    background: #2D65C1;
    color: white;
    border: 1px solid #42404B;
    border-radius: 7px;
  }
}

#toaster {
  z-index: 15;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  .toast {
    width: 90%;
    margin: 5px auto;
    border-radius: 6px;
    border: 2px solid #42404B;
    background: linear-gradient(257deg, #181C27 40.76%, #181C27 77.76%);
    pointer-events: all;

    >div {
      color: #D5B409;
      font-family: "Days One";
      // font-size: 10px; // TODO: remove post iPhone location bug
      text-align: center;
      padding: 1.5vh 0;
    }
  }
}

// IMPORT ALL PAGES
@import "./games/BarDice100/barDice.scss";
@import "./hub/cashier/cashier.scss";
@import "./hub/admin/admin.scss";
@import "./hub/account/_account.scss";
@import "./hub/error/error.scss";