.barDiceTerminal {
  height: inherit;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #000;
  background-image: url('../assets/starryBg.png');
  background-repeat: repeat;
  background-clip: border-box;
}

.barDiceNav {
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
  width: 95%;
  margin: 0 auto;
  padding: 1% 1%;
}

.barDiceTitle {
  display: flex;
  justify-content: space-between;
  font-family: "Days One";
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #e8c305;
  background-color: transparent;
  font-size: 3.5vw;;
  text-wrap: nowrap;
  width: max-content;
  padding: 0 1%;
  border-right: 1px solid #2f3c47;
  border-image: linear-gradient(to bottom, transparent 0 16% #2f3c47 16% 84%, transparent 84% 100%);
}

.barDiceLogo {
  display: flex;
  justify-content: space-between;
}

.barDiceMarquee {
  width: 79%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  font-family: "Days One";
}

.game {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  font-size: 1.5vw;
  font-weight: 600;
  margin: 0 2%;
}

.profit {color: #26E1B0;}
.loss {color: #CF5353;}

.main {
  display: flex;
  justify-content: space-between;
  height: 85%;
  width: 95%;
  margin: 0 auto;
}

.mgtLeft {
  height: 100%;
  width: 78%;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
}

.barDiceChart {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex; 
  flex-direction: column;
  canvas {position: absolute;}
}

.chartText {
  display: flex; 
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  font-family: 'Days One';
  font-size: 10vw;
  &.inGame {
    position: absolute;
    top: 2.5%;
    left: 7.5%;
    color: #e8c305;
  }
  &.preGame, &.postGame {
    height: 100%;
    justify-content: center;
  }
  &.preGame {font-size: 9vw;}
}

.bottom {
  height: 15%;
  display: flex; 
  flex-direction: column;
  justify-content: center;
}

.instructions {
  font-family: 'Days One';
  font-size: 2.5vw;
}

.gamblingWarning {
  color: #888888;
}

.mgtRight {
  width: 20%;
  height: 45%;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;

  &-bottom {
    width: inherit;
    position: absolute;
    bottom: 0;
    background: #000;
    >.gradientBg {
      margin: 2vh 0;
      filter: blur(0px);
    }
  }
}

.title {
  color: #e8c305;
  font-size: 3em;
  font-weight: 700;
}

.barDiceLiveBets {
  font-family: "Days One";
  height: 100%;
  // width: 100%;
}

.headers {
  display: flex;
  justify-content: space-between;
  color: #96AA9D; 
  font-size: 2vw;
  padding: 0 5%;
  .col1 { width: 70%; }
  .col2 { width: 30%; }
}

.table {
  overflow: hidden;
  height: inherit;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  padding: 3% 5%;
  margin-top: 3%;
  font-size: 1.25vw;
  .col1 {
    width: 70%;
    text-overflow: ellipsis;
  }
  .col2 {
    width: 30%;
    text-align: right;
  }
}
.yellow { color: #E8C305;}
.red { color: #ce494c;}

.qrCode {
  display: flex; 
  flex-direction: column;
  padding: 5% 0;
  // qrCode styles set in HTML due to package
  div {
    width: 100%;
    text-align: center;
    font-family: "Days One";
    font-size: 1.5vw;
    font-weight: 700;
    padding-top: 1vh;
  }
}

// .jackpotSignage {
//   width: inherit;
//   position: absolute;
//   bottom: 25.5%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   z-index: 1;
// }

// .jackpotTextWrapper {
//   width: 100%;
//   position: absolute;
//   font-size: 2.25vw;
// }

// .jackpotSubtext {
//   position: absolute;
//   width: 100%;
//   text-align: center;
//   top: 70%;
//   font-size: 0.9vw;
//   font-family: "Days One";
// }

// .jackpotTitle {
//   position:absolute;
//   width: 100%;
//   text-align: center;
//   font-family: 'Days One';
//   font-size: 2.5vw;
//   text-transform: uppercase;
//   color: #8A0B0B;
//   text-shadow: -3px 0 #E8C305, 0 3px #E8C305, 3px 0 #E8C305, 0 -3px #E8C305;
//   top: 0%;
// }