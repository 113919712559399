.switch {
  /* Adjust the width/height of the oval bar */
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* slider styles the long oval bar */
  .slider {
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(30, 40, 80);
    -webkit-transition: .4s;
    transition: .4s;
    /* filter: invert(var(--invert)) */
  }
  /* Overrides background color */
  input:checked + .slider {
    background-color: rgb(128, 128, 158);
  }

  /* Styles the 'dot' or 'circle' */
  .slider:before {
    border-radius: 20px;
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    top: 0px;
    left: 0px;
    /* right: 0px; */
    /* bottom: 0px; */
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  /* Animates how far the 'circle' travels */
  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
  }
}