#cashier {
  font-family: "Open Sans";
  @extend .pageFrame !optional;
}

.cashierRow {
  display: flex;
  justify-content: space-between;
  // margin-top: 1rem;
  // margin-bottom: 2rem;
}

.gradientBg {
  border-radius: 2vh;
  background: #42404b;
  padding: 1px;

  .blackBg {
    background: black;
    background: linear-gradient(
      180deg,
      #181c27 0%,
      rgba(24, 28, 39, 0.95) 100%
    );
    backdrop-filter: blur(11px);
    border-radius: 2vh;
  }
}

.fieldRow {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  border: 2px solid #3F3D48;
  background: #181c27;
  backdrop-filter: blur(11px);
  padding: 5% 3%;
}

nav {
  img {
    width: 30vw;
  }
  .colCenter {@extend .cashierColCenter;}
  .row {@extend .cashierRow;}
}

.wallet {
  // TODO: custom color gradient
  background: linear-gradient(174deg, #27e050 0%, #27e0bf 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 1.1em;
  text-align: center;
  margin: 0 2vw;
}

.username {
  font-size: 0.8em;
  font-weight: 600;
}
.cashierColCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.middle {
  margin: 0 auto;
}
.right {
  margin-left: auto;
}

.back {
  display: flex;
  font-family: "Days One";
  font-size: 16px;
  font-weight: 400;
  color: #e8c305;
  margin: 2% 0 !important;
  &-text {
    margin-left: 8px;
    line-height: 125%;
  }
}

.cashRequest {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > :nth-child(1) {
    .cashierInstructions {
      width: 100%;
      font-weight: 600;
      padding-left: 20px;
    }
    .requestHeader {
      font-size: 1.75em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.requestType {
  margin-top: 3rem;
  .fieldRow {
    margin-bottom: 10%;
    justify-content: left;
  }
  .text {
    color: #c5c5c5;
    margin-left: 5%;
  }
}

.requestDetails {
  width: 100%;
  max-height: initial;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 3%;
  }
  .fieldRow {
    justify-content: left;
  }
  .text {
    margin-left: 5%;
    justify-content: space-between;
    &.iosHack {max-height: 16px !important;}
    &.column {
      display: flex;
      flex-direction: column;
    }
  }
  input,
  select {
    @include invisibleInput;
    width: 100%;
    padding: 0;
    font-size: clamp(11px, 6vw, 24px);
    font-weight: 700;
    font-family: "Open Sans";

    &#amount {
      &::placeholder {
        color: #6d6d71;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.requestCode {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 2.5em;
  font-weight: 700;
  > .fieldRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: clamp(11px, 12vw, 60px);
    height: clamp(11px, 12vw, 60px);
  }
}

.cashierCenter {
  text-align: center;
}

.verifyMessageText {
  font-size: 13px;
  margin-bottom: 0;
}

.cashierCancel {text-decoration: underline;}

.cashierHome {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pixLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
  img {
    height: 60px;
  }
}

.cashierButton {
  width: 100%;
  height: 4rem;
  background: #181c27;
  border: 2px solid #42404b;
  backdrop-filter: blur(11px);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Days One";
  font-weight: 600;
  font-size: 20px;
  color: #c5c5c5;
  cursor: pointer;
  transition: transform 0.2s ease;
  text-transform: uppercase;
  
  &.disabled {filter: blur(2px);}
  &.submit {background: #2d65c1;}
  // &:active {transform: translateY(-3rem);}
}

#cashier>#modal {
  justify-content: flex-end;
}

#cancelRequestModal {
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: space-around;
  // margin: 0 5vw;
  font-family: 'Open Sans';
  background: #181C27;
  border: 2px solid #686868;
  border-radius: 12px;
  padding: 5vw;
  text-align: center;

  .cancelRequestText {
    font-size: clamp(11px, 5.25vw, 40px);
    font-weight: 600;
  }

  .cancelRequestButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >* {
      width: 47%;
      border-radius: 6px;
      border: 2px solid #686868;
      padding: 3vw 0;
    }

    :first-child {background: #2D65C1;}
    :last-child {background: #181C27;}
  }
}